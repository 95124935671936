import type { v1, ExtractFromAPI } from "@netgame/openapi";

import useAsyncFetch from "./useAsyncFetch";
import useIsGuest from "./useIsGuest";

type DailyWheelDataResponse = ExtractFromAPI<v1.paths, "/rest/bonus-offer/get-bonuses/", "get">;

const useGetDailyWheelData = () => {
	const isGuest = useIsGuest();

	return useAsyncFetch({
		path: "/rest/bonus-offer/get-bonuses/",
		method: "get",
		options: {
			immediate: isGuest.value === false,
			watch: [() => isGuest.value],
			cached: true,
			transform: (data: DailyWheelDataResponse) => {
				const { available, wheels, auto, availableDate, endpoint, streakCount, streakCurrent } = data?.dailyWheel ?? {};

				const wheelId = wheels ? Object.keys(wheels)[0] : "1";

				return {
					...data,
					wheelId,
					wheelAvailable: available,
					wheelConfig: {
						auto,
						availableDate,
						endpoint,
						streakCount,
						streakCurrent,
						wheels
					}
				};
			}
		}
	});
};

export default useGetDailyWheelData;
